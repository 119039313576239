import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import GeneralPanel from './pages/General_Panel';
import MyAccount from './pages/My_Account';
import {jwtDecode} from 'jwt-decode'; 
import { Spinner } from 'react-bootstrap';
import UnitaryQuery from './pages/Query/UnitaryQuery';
import UnitaryFields from './pages/Query/UnitaryQuery/UnitaryFields';
import ConvenantSelection from './pages/Query/UnitaryQuery/ConvenantSelection';
import ProductSelection from './pages/Query/UnitaryQuery/SelectProduct';
import ResumeSimulation from './pages/Query/UnitaryQuery/ResumeSimulation';
import ResultSimulation from './pages/Query/UnitaryQuery/ResultSimulation';
import BatchQuery from './pages/Query/BatchQuery';
import ConvenantSelectionBatch from './pages/Query/BatchQuery/ConvenantSelectionBatch';
import SendFile from './pages/Query/BatchQuery/SendFile';
import ReturnBatchQuery from './pages/Query/BatchQuery/ReturnBatchQuery';
import SelectCovenantUnitary from './pages/Covenants/Unitary/SelectCovenant';
import ProductSelectionInss from './pages/Inss/Unitary/SelectProductInss';
import ClientData from './pages/Inss/Unitary/ClientData';
import SelectBanksInss from './pages/Inss/Unitary/SelectBanks';
import ProductSelectionCovenant from './pages/Covenants/Unitary/SelectProductCovenant';
import SelectBanksCovenant from './pages/Covenants/Unitary/SelectBanksCovenant';
import ClientDataCovenant from './pages/Covenants/Unitary/ClientCpf';
import ReturnSimulationCovenant from './pages/Covenants/Unitary/ReturnSimulationCovenant';
import SelectCovenantBatch from './pages/Covenants/Batch/SelectCovenant';
import ProductSelectionCovenantBatch from './pages/Covenants/Batch/SelectProductCovenant';
import SelectBanksCovenantBatch from './pages/Covenants/Batch/SelectBanksCovenant';
import SendFileCovenantBatch from './pages/Covenants/Batch/SendFile';
import ReturnBatchQueryCovenant from './pages/Covenants/Batch/ReturnBatchCovenant';
import SearchCovenant from './pages/SearchCovenant';
import ResultSimulationInss from './pages/Inss/Unitary/ResultSimulation';
import ProductSelectionInssBatch from './pages/Inss/Batch/SelectProductInssBatch';
import SelectBanksInssBatch from './pages/Inss/Batch/SelectBanksBatch';
import SendFileInssBatch from './pages/Inss/Batch/SendFileBatchInss';
import ReturnBatchQueryInss from './pages/Inss/Batch/ReturnBatchInss';
import MySimulations from './pages/MySimulations';
import Notifications from './pages/Notifications';
import VirtualWallet from './pages/VirtualWallet';
import SelectBanksInssTypingBatch from './pages/Typing/Inss/Batch/SelectBanks';
import SendFileInssBatchTyping from './pages/Typing/SendFileInssBatchTyping';
import TypingList from './pages/Typing/TypingList';

async function checkAuthentication() {
    const token = localStorage.getItem('token');

    if (!token) {
        return false;
    }

    try {
        const decodedToken = jwtDecode(token);
        const expiration = decodedToken.exp;
        const currentTime = Math.floor(Date.now() / 1000);

        if (expiration <= currentTime) {
            console.log("Token expirado");
            localStorage.removeItem('token');
            localStorage.clear();
            alert("Acesso expirado! Logar novamente.");
            window.location.href = '/login';
            return false;
        }

        return true;
    } catch (error) {
        console.error('Erro ao decodificar o token:', error);
        return false;
    }
}

function ProtectedRoute({ element: Component, ...rest }) {
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    useEffect(() => {
        const authenticate = async () => {
            const result = await checkAuthentication();
            setIsAuthenticated(result);
        };

        authenticate();
    }, []);

    if (isAuthenticated === null) {
        return <Spinner animation="border" />;
    }

    return isAuthenticated ? <Component {...rest} /> : <Navigate to="/login" />;
}

export default function AppRoutes() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Navigate to="/login" />} />
                <Route path="/login" element={<Login />} />
                <Route path="/PainelGeral" element={<ProtectedRoute element={GeneralPanel} />} />
                <Route path="/MinhaConta" element={<ProtectedRoute element={MyAccount}  />} />
                <Route path="/ListagemSimulacoes" element={<ProtectedRoute element={MySimulations}  />} />
                <Route path="/Notificacoes" element={<ProtectedRoute element={Notifications}  />} />

                <Route path="/ConsultaUnitaria" element={<ProtectedRoute element={UnitaryQuery} />} />

                {/* Convenios - Unitaria */}
                <Route path="/ConsultaUnitaria/Convenios" element={<ProtectedRoute element={SelectCovenantUnitary} />} />
                <Route path="/ConsultaUnitaria/Convenios/SelecaoProduto" element={<ProtectedRoute element={ProductSelectionCovenant} />} />
                <Route path="/ConsultaUnitaria/Convenios/SelecaoBancos" element={<ProtectedRoute element={SelectBanksCovenant} />} />
                <Route path="/ConsultaUnitaria/Convenio/DadosCliente" element={<ProtectedRoute element={ClientDataCovenant} />} />
                <Route path="/ConsultaUnitaria/Convenio/Resultado" element={<ProtectedRoute element={ReturnSimulationCovenant} />} />
                
                {/* Convenios - Em Lote */}
                <Route path="/ConsultaLote/Convenios" element={<ProtectedRoute element={SelectCovenantBatch} />} />
                <Route path="/ConsultaLote/Convenios/SelecaoProduto" element={<ProtectedRoute element={ProductSelectionCovenantBatch} />} />
                <Route path="/ConsultaLote/Convenios/SelecaoBancos" element={<ProtectedRoute element={SelectBanksCovenantBatch} />} />
                <Route path="/ConsultaLote/Convenios/EnvioArquivo" element={<ProtectedRoute element={SendFileCovenantBatch} />} />
                <Route path='/ConsultaLote/Convenios/Retorno' element={<ProtectedRoute element={ReturnBatchQueryCovenant} />}/>

                {/* INSS - Unitaria*/}
                <Route path="/ConsultaUnitaria/Inss/Produtos" element={<ProtectedRoute element={ProductSelectionInss} />} />
                <Route path="/ConsultaUnitaria/Inss/DadosCliente" element={<ProtectedRoute element={ClientData} />} />
                <Route path="/ConsultaUnitaria/Inss/SelecaoBancos" element={<ProtectedRoute element={SelectBanksInss} />} />
                <Route path="/ConsultaUnitaria/Inss/Resultado" element={<ProtectedRoute element={ResultSimulationInss}/>} />

                {/* INSS - Em Lote */}
                <Route path="/ConsultaLote/INSS/" element={<ProtectedRoute element={ProductSelectionInssBatch}/>} />
                <Route path="/ConsultaLote/INSS/SelecaoBancos" element={<ProtectedRoute element={SelectBanksInssBatch}/>} />
                <Route path="/ConsultaLote/Inss/EnvioArquivo" element={<ProtectedRoute element={SendFileInssBatch}/>} />
                <Route path="/ConsultaLote/Inss/Retorno" element={<ProtectedRoute element={ReturnBatchQueryInss}/>} />
                

                {/* Consulta Convenios */}
                <Route path="/PesquisaConvenios" element={<ProtectedRoute element={SearchCovenant} />} />

                <Route path="/ConsultaLote" element={<ProtectedRoute element={BatchQuery} />} />
                <Route path="/CamposUnitarios" element={<ProtectedRoute element={UnitaryFields} />} />
                <Route path="/SelecaoConvenios" element={<ProtectedRoute element={ConvenantSelection} />} />
                <Route path="/ConsultaLote/SelecaoConvenios" element={<ProtectedRoute element={ConvenantSelectionBatch} />} />
                <Route path="/ConsultaLote/EnvioArquivo" element={<ProtectedRoute element={SendFile} />} />
                <Route path="/ConsultaLote/Retorno" element={<ProtectedRoute element={ReturnBatchQuery} />} />
                <Route path="/SelecaoProdutos" element={<ProtectedRoute element={ProductSelection} />} />
                <Route path="/RevisaoSimulacao" element={<ProtectedRoute element={ResumeSimulation} />} />
                <Route path="/ResultadoSimulacao" element={<ProtectedRoute element={ResultSimulation} />} />

                {/* Digitações */}
                <Route path="/DigitacoesLote/INSS/SelecaoBancos" element={<ProtectedRoute element={SelectBanksInssTypingBatch} />} />
                <Route path="/DigitacoesLote/INSS/EnvioArquivo" element={<ProtectedRoute element={SendFileInssBatchTyping} />} />
                <Route path="/Digitacoes/Agendadas" element={<ProtectedRoute element={TypingList} />} />

                <Route path="/CarteiraVirtual" element={<ProtectedRoute element={VirtualWallet} />} />
            </Routes>
        </Router>
    );
}
