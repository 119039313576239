import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import styles from "./ClientModal.module.scss";
import { Client } from "../../../types/client";
import axios from "axios";
import ToastWarning from "../../ToastWarning";

type Props = {
  show: boolean;
  clients: Client[];
  onClose: () => void;
  onSave: (updatedClient: Client) => void;
};

export default function ClientModal({ show, clients, onClose, onSave }: Props) {
  const [clientName, setClientName] = useState<string>("");
  const [cpfClient, setCpfClient] = useState<string>("");
  const [registrationClient, setRegistrationClient] = useState<string>("");
  const [birthDateClient, setBirthDateClient] = useState<string>("");
  const [genderClient, setGenderClient] = useState<string>("");
  const [incomeAmountClient, setIncomeAmountClient] = useState<string>("");
  const [maritalStatusClient, setMaritalStatusClient] = useState<string>("");
  const [isPoliticallyExposed, setIsPoliticallyExposed] = useState<"SIM" | "NAO">("NAO");
  const [documentClient, setDocumentClient] = useState<string>("");
  const [issuingUnitClient, setIssuingUnitClient] = useState<string>("");
  const [issuingOrgClient, setIssuingOrgClient] = useState<string>("");
  const [dateOfIssueClient, setDateOfIssueClient] = useState<string>("");
  const [phoneAreaCodeClient, setPhoneAreaCodeClient] = useState<string>("");
  const [phoneStringClient, setPhoneStringClient] = useState<string>("");
  const [receivesBenefitsViaCard, setReceivesBenefitsViaCard] = useState<"SIM" | "NAO">("NAO");
  const [federativeUnitOfBenefit, setFederativeUnitOfBenefit] = useState<string>("");

  // dados da operação
  const [installmentAmount, setInstallmentAmount] = useState<string>("");
  const [requestedAmount, setRequestedAmount] = useState<string>("");
  const [formalizationType, setFormalizationType] = useState<string>("");
  const [bankOperation, setBankOperation] = useState<string>("");
  

  // dados bancarios
  const [bankCodeClient, setBankCodeClient] = useState<string>("");
  const [bankNameClient, setBankNameClient] = useState<string>("");
  const [bankAgencyClient, setBankAgencyClient] = useState<string>("");
  const [bankAgencyDigitClient, setBankAgencyDigitClient] = useState<string | null>(null);
  const [bankAccountClient, setBankAccountClient] = useState<string>("");
  const [bankAccountDigitClient, setBankAccountDigitClient] = useState<string | null>(null);

  // endereço
  const [streetClient, setStreetClient] = useState<string>("");
  const [numberAdress, setNumberAdressClient] = useState<string>("");
  const [neighborhoodClient, setNeighborhoodClient] = useState<string>("");
  const [cityClient, setCityClient] = useState<string>("");
  const [federativeUnitAddress, setFederativeUnitAddress] = useState<string>("");
  const [zipCodeClient, setZipCodeClient] = useState<string>("");

  const [toastMessage, setToastMessage] = useState('');


  useEffect(() => {
    if (bankCodeClient) {
      // Faz a requisição GET para buscar o nome do banco
      axios
        .get(`https://brasilapi.com.br/api/banks/v1/${bankCodeClient}`)
        .then((response) => {
          setBankNameClient(response.data.name); // Atualiza o nome do banco
        })
        .catch((error) => {
          console.error("Erro ao buscar nome do banco:", error);
          setBankNameClient(""); // Caso haja erro, limpa o nome do banco
        });
    } else {
      setBankNameClient(""); // Limpa o nome do banco caso o código seja apagado
    }
  }, [bankCodeClient]);

  const formatDate = (date: string): string => {
    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) {
      return ""; // Retorna vazio se a data for inválida
    }
    const day = String(parsedDate.getDate()).padStart(2, "0");
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0"); // Os meses começam em 0
    const year = parsedDate.getFullYear();
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    const initialDate = clients[0]?.client?.birthDate;
    if (initialDate) {
      setBirthDateClient(formatDate(initialDate)); // Formata diretamente para dd/mm/yyyy
    }
  }, [clients]);

  useEffect(() => {
    if (clients.length > 0) {
      setClientName(clients[0].client.name || "");
      setCpfClient(clients[0].client.CPF || "");
      setRegistrationClient(clients[0].client.registration || "");
      setBirthDateClient(clients[0].client.birthDate || "");
      setGenderClient(clients[0].client.gender || "");
      setIncomeAmountClient(clients[0].client.incomeAmount || "");
      setMaritalStatusClient(clients[0].client.maritalStatus || "");
      setIsPoliticallyExposed(clients[0].client.IsPoliticallyExposedPerson || "NAO");
      setDocumentClient(clients[0].client.generalRegistyDocumento.document || "");
      setIssuingUnitClient(clients[0].client.generalRegistyDocumento.issuingFederativeUnit || "");
      setIssuingOrgClient(clients[0].client.generalRegistyDocumento.issuingOrganization || "");
      setDateOfIssueClient(clients[0].client.generalRegistyDocumento.dateOfIssue || "");
      setPhoneAreaCodeClient(clients[0].client.phone.areaCode || "");
      setPhoneStringClient(clients[0].client.phone.number || "");
      setBankCodeClient(clients[0].client.bank.code || "");
      setBankAgencyClient(clients[0].client.bank.agency.number || "");
      setBankAgencyDigitClient(clients[0].client.bank.agency.digit || null);
      setBankAccountClient(clients[0].client.bank.account.number || "");
      setBankAccountDigitClient(clients[0].client.bank.account.digit || null);
      setReceivesBenefitsViaCard(clients[0].client.benefit.receivesBenefitsViaCard || "NAO");
      setFederativeUnitOfBenefit(clients[0].client.benefit.federativeUnitOfTheBenefit || "");
      setStreetClient(clients[0].client.address.street || "");
      setNumberAdressClient(clients[0].client.address.number || "");
      setNeighborhoodClient(clients[0].client.address.neighborhood || "");
      setCityClient(clients[0].client.address.city || "");
      setFederativeUnitAddress(clients[0].client.address.federativeUnit || "");
      setZipCodeClient(clients[0].client.address.zipCode || "");
      setInstallmentAmount(clients[0].creditCondition.installmentAmount || "")
      setRequestedAmount(clients[0].creditCondition.requestedAmount || "")
      setFormalizationType(clients[0].creditCondition.formalizationType || "")
      setBankOperation(clients[0].creditCondition.selectedBank.bankFullName || "")
    }
  }, [clients]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setClientName(e.target.value);
  const handleChangeCpf = (e: React.ChangeEvent<HTMLInputElement>) => setCpfClient(e.target.value);
  const handleChangeRegistration = (e: React.ChangeEvent<HTMLInputElement>) => setRegistrationClient(e.target.value);
  const handleChangeBirthDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const dateParts = value.split("/");
    const isValidDate =
      dateParts.length === 3 &&
      !isNaN(new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`).getTime());

    if (isValidDate) {
      setBirthDateClient(value);
    }
  };

  const handleChangeGender = (e: React.ChangeEvent<HTMLInputElement>) => setGenderClient(e.target.value);
  const handleChangeIncomeAmount = (e: React.ChangeEvent<HTMLInputElement>) => setIncomeAmountClient(e.target.value);
  const handleChangeMaritalStatus = (e: React.ChangeEvent<HTMLInputElement>) => setMaritalStatusClient(e.target.value);
  const handleChangeIsPoliticallyExposed = (e: React.ChangeEvent<HTMLInputElement>) => setIsPoliticallyExposed(e.target.value as "SIM" | "NAO");
  const handleChangeDocument = (e: React.ChangeEvent<HTMLInputElement>) => setDocumentClient(e.target.value);
  const handleChangeIssuingUnit = (e: React.ChangeEvent<HTMLInputElement>) => setIssuingUnitClient(e.target.value);
  const handleChangeIssuingOrg = (e: React.ChangeEvent<HTMLInputElement>) => setIssuingOrgClient(e.target.value);
  const handleChangeDateOfIssue = (e: React.ChangeEvent<HTMLInputElement>) => setDateOfIssueClient(e.target.value);
  const handleChangePhoneAreaCode = (e: React.ChangeEvent<HTMLInputElement>) => setPhoneAreaCodeClient(e.target.value);
  const handleChangePhoneString = (e: React.ChangeEvent<HTMLInputElement>) => setPhoneStringClient(e.target.value);
  const handleChangeBankCode = (e: React.ChangeEvent<HTMLInputElement>) => setBankCodeClient(e.target.value);
  const handleChangeBankAgency = (e: React.ChangeEvent<HTMLInputElement>) => setBankAgencyClient(e.target.value);
  const handleChangeBankAgencyDigit = (e: React.ChangeEvent<HTMLInputElement>) => setBankAgencyDigitClient(e.target.value);
  const handleChangeBankAccount = (e: React.ChangeEvent<HTMLInputElement>) => setBankAccountClient(e.target.value);
  const handleChangeBankAccountDigit = (e: React.ChangeEvent<HTMLInputElement>) => setBankAccountDigitClient(e.target.value);
  const handleChangeReceivesBenefitsViaCard = (e: React.ChangeEvent<HTMLInputElement>) => setReceivesBenefitsViaCard(e.target.value as "SIM" | "NAO");
  const handleChangeFederativeUnitOfBenefit = (e: React.ChangeEvent<HTMLInputElement>) => setFederativeUnitOfBenefit(e.target.value);
  const handleChangeStreet = (e: React.ChangeEvent<HTMLInputElement>) => setStreetClient(e.target.value);
  const handleChangeString = (e: React.ChangeEvent<HTMLInputElement>) => setNumberAdressClient(e.target.value);
  const handleChangeNeighborhood = (e: React.ChangeEvent<HTMLInputElement>) => setNeighborhoodClient(e.target.value);
  const handleChangeCity = (e: React.ChangeEvent<HTMLInputElement>) => setCityClient(e.target.value);
  const handleChangeFederativeUnitAddress = (e: React.ChangeEvent<HTMLInputElement>) => setFederativeUnitAddress(e.target.value);
  const handleChangeZipCode = (e: React.ChangeEvent<HTMLInputElement>) => setZipCodeClient(e.target.value);
  const handleChangeInstallmentAmount = (e: React.ChangeEvent<HTMLInputElement>) => setInstallmentAmount(e.target.value);
  const handleChangeRequestedAmount = (e: React.ChangeEvent<HTMLInputElement>) => setRequestedAmount(e.target.value);
  const handleChangeFormalizationType = (e: React.ChangeEvent<HTMLInputElement>) => setFormalizationType(e.target.value);
  const handleChangeBankOperation = (e: React.ChangeEvent<HTMLInputElement>) => setBankOperation(e.target.value);

  const handleSave = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token não encontrado");
      }
  
      // ID do cliente
      const clientId = clients[0]._id;
      if (!clientId) {
        throw new Error("ID do cliente não encontrado");
      }
  
      // Campos e valores a serem atualizados
      const fieldsToUpdate = [];
      const newValues = [];
  
      // Exemplo: Adicionar os campos dinamicamente
      if (clientName) {
        fieldsToUpdate.push("data.client.name");
        newValues.push(clientName);
      }
      
      if (cpfClient) {
        fieldsToUpdate.push("data.client.CPF");
        newValues.push(cpfClient);
      }
      
      if (registrationClient) {
        fieldsToUpdate.push("data.client.registration");
        newValues.push(registrationClient);
      }
      
      if (birthDateClient) {
        fieldsToUpdate.push("data.client.birthDate");
        newValues.push(birthDateClient);
      }
      
      if (genderClient) {
        fieldsToUpdate.push("data.client.gender");
        newValues.push(genderClient);
      }
      
      if (incomeAmountClient) {
        fieldsToUpdate.push("data.client.incomeAmount");
        newValues.push(incomeAmountClient);
      }
      
      if (maritalStatusClient) {
        fieldsToUpdate.push("data.client.maritalStatus");
        newValues.push(maritalStatusClient);
      }
      
      if (isPoliticallyExposed) {
        fieldsToUpdate.push("data.client.IsPoliticallyExposedPerson");
        newValues.push(isPoliticallyExposed);
      }
      
      if (documentClient) {
        fieldsToUpdate.push("data.client.generalRegistyDocumento.document");
        newValues.push(documentClient);
      }
      
      if (issuingUnitClient) {
        fieldsToUpdate.push("data.client.generalRegistyDocumento.issuingFederativeUnit");
        newValues.push(issuingUnitClient);
      }
      
      if (issuingOrgClient) {
        fieldsToUpdate.push("data.client.generalRegistyDocumento.issuingOrganization");
        newValues.push(issuingOrgClient);
      }
      
      if (dateOfIssueClient) {
        fieldsToUpdate.push("data.client.generalRegistyDocumento.dateOfIssue");
        newValues.push(dateOfIssueClient);
      }
      
      if (phoneAreaCodeClient) {
        fieldsToUpdate.push("data.client.phone.areaCode");
        newValues.push(phoneAreaCodeClient);
      }
      
      if (phoneStringClient) {
        fieldsToUpdate.push("data.client.phone.number");
        newValues.push(phoneStringClient);
      }
      
      if (bankCodeClient) {
        fieldsToUpdate.push("data.client.bank.code");
        newValues.push(bankCodeClient);
      }
      
      if (bankAgencyClient) {
        fieldsToUpdate.push("data.client.bank.agency.number");
        newValues.push(bankAgencyClient);
      }
      
      if (bankAgencyDigitClient) {
        fieldsToUpdate.push("data.client.bank.agency.digit");
        newValues.push(bankAgencyDigitClient);
      }
      
      if (bankAccountClient) {
        fieldsToUpdate.push("data.client.bank.account.number");
        newValues.push(bankAccountClient);
      }
      
      if (bankAccountDigitClient) {
        fieldsToUpdate.push("data.client.bank.account.digit");
        newValues.push(bankAccountDigitClient);
      }
      
      if (receivesBenefitsViaCard) {
        fieldsToUpdate.push("data.client.benefit.receivesBenefitsViaCard");
        newValues.push(receivesBenefitsViaCard);
      }
      
      if (federativeUnitOfBenefit) {
        fieldsToUpdate.push("data.client.benefit.federativeUnitOfTheBenefit");
        newValues.push(federativeUnitOfBenefit);
      }
      
      if (streetClient) {
        fieldsToUpdate.push("data.client.address.street");
        newValues.push(streetClient);
      }
      
      if (numberAdress) {
        fieldsToUpdate.push("data.client.address.number");
        newValues.push(numberAdress);
      }
      
      if (neighborhoodClient) {
        fieldsToUpdate.push("data.client.address.neighborhood");
        newValues.push(neighborhoodClient);
      }
      
      if (cityClient) {
        fieldsToUpdate.push("data.client.address.city");
        newValues.push(cityClient);
      }
      
      if (federativeUnitAddress) {
        fieldsToUpdate.push("data.client.address.federativeUnit");
        newValues.push(federativeUnitAddress);
      }
      
      if (zipCodeClient) {
        fieldsToUpdate.push("data.client.address.zipCode");
        newValues.push(zipCodeClient);
      }
      
      if (installmentAmount) {
        fieldsToUpdate.push("data.creditCondition.installmentAmount");
        newValues.push(installmentAmount);
      }
      
      if (requestedAmount) {
        fieldsToUpdate.push("data.creditCondition.requestedAmount");
        newValues.push(requestedAmount);
      }
      
      if (formalizationType) {
        fieldsToUpdate.push("data.creditCondition.formalizationType");
        newValues.push(formalizationType);
      }
      
      if (bankOperation) {
        fieldsToUpdate.push("data.creditCondition.selectedBank.bankFullName");
        newValues.push(bankOperation);
      }
  
      if (fieldsToUpdate.length === 0 || newValues.length === 0) {
        throw new Error("Nenhum campo ou valor fornecido para atualização");
      }
  
      // Configurar o payload da requisição
      const payload = {
        _id: clientId,
        fieldsToUpdate,
        newValues,
      };

      console.log("Payload enviado:", JSON.stringify(payload, null, 2));
  
      // Fazer a requisição PATCH para atualizar os campos
      const response = await axios.patch(
        "https://acelereai.gfttech.com.br/api/v1/proposal/batch-digitation/scheduled/update-fields",
        JSON.stringify(payload),
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      // Verificar a resposta da API
      if (response.status === 200) {
        console.log("Campos atualizados com sucesso:", response.data);
        setToastMessage('Dados salvos com sucesso!');
        setTimeout(() => setToastMessage(''), 4000);
        onClose();

      } else {
        console.error("Erro ao atualizar os campos:", response.statusText);
      }
    } catch (error) {
      // console.error("Erro durante a atualização dos campos:", error.message);
    }
  };
  

  return (
    <Modal show={show} onHide={onClose} dialogClassName={styles.largeModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Editar Dados do Cliente</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <h5 className="mb-4">Dados Pessoais</h5>
          <Row className="mt-3">
            <Col sm={12} md={4}>
              <Form.Group className={styles.floatingLabel}>
                <Form.Control
                  type="text"
                  value={clientName}
                  onChange={handleChange}
                  placeholder="Nome"
                />
                <Form.Label>Nome</Form.Label>
              </Form.Group>
            </Col>
            <Col sm={12} md={3}>
              <Form.Group className={styles.floatingLabel}>
                <Form.Control
                  type="text"
                  value={cpfClient}
                  onChange={handleChangeCpf}
                  placeholder="CPF"
                />
                <Form.Label>CPF</Form.Label>
              </Form.Group>
            </Col>
            <Col sm={12} md={3}>
              <Form.Group className={styles.floatingLabel}>
                <Form.Control
                  type="text"
                  value={registrationClient}
                  onChange={handleChangeRegistration}
                  placeholder="Matricula"
                />
                <Form.Label>Matricula</Form.Label>
              </Form.Group>
            </Col>
            <Col sm={12} md={2}>
              <Form.Group className={styles.floatingLabel}>
                <Form.Control
                  type="text"
                  value={formatDate(birthDateClient)}
                  onChange={handleChangeBirthDate}
                  placeholder="Dt. Nascimento"
                  className="form-control"
                />
                <Form.Label>Dt. Nascimento</Form.Label>
              </Form.Group>
            </Col>

          </Row>
          <Row className="mt-4">
            <Col sm={12} md={3}>
              <Form.Group className={styles.floatingLabel}>
                <Form.Control
                  type="text"
                  value={documentClient}
                  onChange={handleChangeDocument}
                  placeholder="RG"
                />
                <Form.Label>RG</Form.Label>
              </Form.Group>
            </Col>
            <Col sm={12} md={3}>
              <Form.Group className={styles.floatingLabel}>
                <Form.Control
                  type="text"
                  value={issuingOrgClient}
                  onChange={handleChangeIssuingOrg}
                  placeholder="Orgão Emissor"
                />
                <Form.Label>Orgão Emissor</Form.Label>
              </Form.Group>
            </Col>
            <Col sm={12} md={3}>
              <Form.Group className={styles.floatingLabel}>
                <Form.Control
                  type="text"
                  value={issuingUnitClient}
                  onChange={handleChangeIssuingUnit}
                  placeholder="UF Emissão"
                />
                <Form.Label>UF Emissão</Form.Label>
              </Form.Group>
            </Col>
            <Col sm={12} md={3}>
              <Form.Group className={styles.floatingLabel}>
                <Form.Control
                  type="text"
                  value={new Date(dateOfIssueClient).toLocaleDateString()}
                  onChange={handleChangeDateOfIssue}
                  placeholder="Dt. Emissão"
                />
                <Form.Label>Dt. Emissão</Form.Label>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col sm={12} md={3}>
              <Form.Group className={styles.floatingLabel}>
                <Form.Control
                  type="text"
                  value={genderClient}
                  onChange={handleChangeGender}
                  placeholder="Gênero"
                />
                <Form.Label>Gênero</Form.Label>
              </Form.Group>
            </Col>
            <Col sm={12} md={3}>
              <Form.Group className={styles.floatingLabel}>
                <Form.Control
                  type="text"
                  value={maritalStatusClient}
                  onChange={handleChangeMaritalStatus}
                  placeholder="Estado Civil"
                />
                <Form.Label>Estado Civil</Form.Label>
              </Form.Group>
            </Col>
            <Col sm={12} md={3}>
              <Form.Group className={styles.floatingLabel}>
                <Form.Control
                  type="text"
                  value={incomeAmountClient}
                  onChange={handleChangeIncomeAmount}
                  placeholder="Renda"
                />
                <Form.Label>Renda</Form.Label>
              </Form.Group>
            </Col>
            <Col sm={12} md={3}>
              <Form.Group className={styles.floatingLabel}>
                <Form.Control
                  type="text"
                  value={isPoliticallyExposed}
                  onChange={handleChangeIsPoliticallyExposed}
                  placeholder="Renda"
                />
                <Form.Label>Pessoa Politicamente Exposta?</Form.Label>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col sm={12} md={2}>
              <Form.Group className={styles.floatingLabel}>
                <Form.Control
                  type="text"
                  value={phoneAreaCodeClient}
                  onChange={handleChangePhoneAreaCode}
                  placeholder="DDD"
                />
                <Form.Label>DDD</Form.Label>
              </Form.Group>
            </Col>
            <Col sm={12} md={4}>
              <Form.Group className={styles.floatingLabel}>
                <Form.Control
                  type="text"
                  value={phoneStringClient}
                  onChange={handleChangePhoneString}
                  placeholder="Telefone"
                />
                <Form.Label>Telefone</Form.Label>
              </Form.Group>
            </Col>
            <Col sm={12} md={3}>
              <Form.Group className={styles.floatingLabel}>
                <Form.Control
                  type="text"
                  value={federativeUnitOfBenefit}
                  onChange={handleChangeFederativeUnitOfBenefit}
                  placeholder="UF Benefício"
                />
                <Form.Label>UF Benefício</Form.Label>
              </Form.Group>
            </Col>
            <Col sm={12} md={3}>
              <Form.Group className={styles.floatingLabel}>
                <Form.Control
                  type="text"
                  value={receivesBenefitsViaCard}
                  onChange={handleChangeReceivesBenefitsViaCard}
                  placeholder="Recebe Via Cartão"
                />
                <Form.Label>Recebe Via Cartão</Form.Label>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col sm={12} md={4}>
              <Form.Group className={styles.floatingLabel}>
                <Form.Control
                  type="text"
                  value={zipCodeClient}
                  onChange={handleChangeZipCode}
                  placeholder="CEP"
                />
                <Form.Label>CEP</Form.Label>
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group className={styles.floatingLabel}>
                <Form.Control
                  type="text"
                  value={streetClient}
                  onChange={handleChangeStreet}
                  placeholder="Logradouro"
                />
                <Form.Label>Logradouro</Form.Label>
              </Form.Group>
            </Col>
            <Col sm={12} md={2}>
              <Form.Group className={styles.floatingLabel}>
                <Form.Control
                  type="text"
                  value={numberAdress}
                  onChange={handleChangeString}
                  placeholder="Número"
                />
                <Form.Label>Número</Form.Label>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col sm={12} md={5}>
              <Form.Group className={styles.floatingLabel}>
                <Form.Control
                  type="text"
                  value={neighborhoodClient}
                  onChange={handleChangeNeighborhood}
                  placeholder="Bairro"
                />
                <Form.Label>Bairro</Form.Label>
              </Form.Group>
            </Col>
            <Col sm={12} md={5}>
              <Form.Group className={styles.floatingLabel}>
                <Form.Control
                  type="text"
                  value={cityClient}
                  onChange={handleChangeCity}
                  placeholder="Cidade"
                />
                <Form.Label>Cidade</Form.Label>
              </Form.Group>
            </Col>
            <Col sm={12} md={2}>
              <Form.Group className={styles.floatingLabel}>
                <Form.Control
                  type="text"
                  value={federativeUnitAddress}
                  onChange={handleChangeFederativeUnitAddress}
                  placeholder="UF"
                />
                <Form.Label>UF</Form.Label>
              </Form.Group>
            </Col>
          </Row>
          <h5 className="mt-4">Dados da Operação</h5>
          <Row className="mt-4">
            <Col sm={12} md={3}>
              <Form.Group className={styles.floatingLabel}>
                <Form.Control
                  type="text"
                  value={bankOperation}
                  onChange={handleChangeBankOperation}
                  placeholder="Banco"
                />
                <Form.Label>Banco</Form.Label>
              </Form.Group>
            </Col>
            <Col sm={12} md={3}>
              <Form.Group className={styles.floatingLabel}>
                <Form.Control
                  type="text"
                  value={installmentAmount}
                  onChange={handleChangeInstallmentAmount}
                  placeholder="Valor de Parcela"
                />
                <Form.Label>Valor de Parcela</Form.Label>
              </Form.Group>
            </Col>
            <Col sm={12} md={3}>
              <Form.Group className={styles.floatingLabel}>
                <Form.Control
                  type="text"
                  value={requestedAmount}
                  onChange={handleChangeRequestedAmount}
                  placeholder="Valor Solicitado"
                />
                <Form.Label>Valor Solicitado</Form.Label>
              </Form.Group>
            </Col>
            <Col sm={12} md={3}>
              <Form.Group className={styles.floatingLabel}>
                <Form.Control
                  type="text"
                  value={formalizationType}
                  readOnly
                  onChange={handleChangeFormalizationType}
                  placeholder="Tipo de Formalização"
                />
                <Form.Label>Tipo de Formalização</Form.Label>
              </Form.Group>
            </Col>
          </Row>

          <h5 className="mt-4">Dados Bancários</h5>
          <Row className="mt-4">
            <Col sm={12} md={3}>
              <Form.Group className={styles.floatingLabel}>
                <Form.Control
                  type="text"
                  value={bankCodeClient}
                  onChange={handleChangeBankCode}
                  placeholder="Cod. Banco"
                />
                <Form.Label>Cod. Banco</Form.Label>
              </Form.Group>
            </Col>
            <Col sm={12} md={9}>
              <Form.Group className={styles.floatingLabel}>
                <Form.Control
                  type="text"
                  value={bankNameClient}
                  readOnly
                  placeholder="Nome Banco"
                />
                <Form.Label>Nome Banco</Form.Label>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col sm={12} md={4}>
              <Form.Group className={styles.floatingLabel}>
                <Form.Control
                  type="text"
                  value={bankAgencyClient}
                  onChange={handleChangeBankAgency}
                  placeholder="Agência"
                />
                <Form.Label>Agência</Form.Label>
              </Form.Group>
            </Col>
            <Col sm={12} md={2}>
              <Form.Group className={styles.floatingLabel}>
                <Form.Control
                  type="text"
                  value={bankAgencyDigitClient?.toString()}
                  onChange={handleChangeBankAgencyDigit}
                  placeholder="Dig Agência"
                />
                <Form.Label>Dig Agência</Form.Label>
              </Form.Group>
            </Col>
            <Col sm={12} md={4}>
              <Form.Group className={styles.floatingLabel}>
                <Form.Control
                  type="text"
                  value={bankAccountClient}
                  onChange={handleChangeBankAccount}
                  placeholder="Conta"
                />
                <Form.Label>Conta</Form.Label>
              </Form.Group>
            </Col>
            <Col sm={12} md={2}>
              <Form.Group className={styles.floatingLabel}>
                <Form.Control
                  type="text"
                  value={bankAccountDigitClient?.toString()}
                  onChange={handleChangeBankAccountDigit}
                  placeholder="Dig Conta"
                />
                <Form.Label>Dig Conta</Form.Label>
              </Form.Group>
            </Col>
          </Row>
        </Form>
        <ToastWarning message={toastMessage} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Fechar
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Salvar Alterações
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
