import { useState, useEffect } from "react";
import { Container, Table, Row, Col, Form, Pagination } from "react-bootstrap";
import Select from "react-select";
import NavBar from "../../../components/NavBar";
import Logo from "../../../components/Logo";
import styles from "./TypingList.module.scss";
import ClientModal from "../../../components/Modals/ClientTyping";
import ButtonGrayUltimate from "../../../components/Buttons/ButtonGrayUltimate";
import axios from "axios";
import { Client } from "../../../types/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";

export default function TypingList() {
  const [menuOpen, setMenuOpen] = useState<boolean>(localStorage.getItem("isMenuOpen") === "true");
  const [filter, setFilter] = useState<string>(""); // Filtro de nome ou CPF
  const [operationFilter, setOperationFilter] = useState<string | null>(null); // Filtro de operação
  const [bankFilter, setBankFilter] = useState<string | null>(null); // Filtro de banco
  const [dateRange, setDateRange] = useState<{ start: string; end: string }>({ start: "", end: "" });
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [clients, setClients] = useState<Client[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage] = useState<number>(5);
  const [operationOptions, setOperationOptions] = useState<{ value: string; label: string }[]>([]); // Opções de operação
  const [bankOptions, setBankOptions] = useState<{ value: string; label: string }[]>([]); // Opções de banco

  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get(
          `https://acelereai.gfttech.com.br/api/v1/proposal/batch-digitation/scheduled/find-all`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const fetchedClients = response.data || [];
        setClients(fetchedClients);

        // Extrair opções de operação e banco dos dados
        const operations = Array.from(
          new Set(fetchedClients.map((client: Client) => client.creditCondition.formalizationType))
        ).map((operation) => ({ value: operation as string, label: operation as string })); // Asserção de tipo para string

        const banks = Array.from(
          new Set(fetchedClients.map((client: Client) => client.creditCondition.selectedBank.bankFullName))
        ).map((bankCode) => ({ value: bankCode as string, label: `${bankCode as string}` })); // Asserção de tipo para string

        setOperationOptions(operations);
        setBankOptions(banks);
      } catch (error) {
        console.error("Erro ao buscar clientes:", error);
      }
    };

    if (token) {
      fetchClients();
    }
  }, [token]);

  const filteredClients = clients.filter((client) => {
    const matchesFilter =
      client.client.name.toLowerCase().includes(filter.toLowerCase()) ||
      client.client.CPF.toString().includes(filter);
    const matchesOperation = operationFilter
      ? client.creditCondition.formalizationType === operationFilter
      : true;
    const matchesBank = bankFilter ? client.client.bank.code.toString() === bankFilter : true;
    const matchesDateRange =
      (!dateRange.start || new Date(client.date) >= new Date(dateRange.start)) &&
      (!dateRange.end || new Date(client.date) <= new Date(dateRange.end));
    return matchesFilter && matchesOperation && matchesBank && matchesDateRange;
  });

  // Atualizar o total de páginas com base nos clientes filtrados
  useEffect(() => {
    const totalFilteredPages = Math.ceil(filteredClients.length / itemsPerPage);
    setTotalPages(totalFilteredPages);
  }, [filteredClients, itemsPerPage]);

  // Garantir que a página atual seja válida
  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(1);
    }
  }, [totalPages, currentPage]);

  const currentPageClients = filteredClients.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page: number) => setCurrentPage(page);

  return (
    <>
      <section className={`${styles.navbarSection} mt-5`}>
        <NavBar onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)} />
      </section>
      <Logo />

      <Container
        className={`${styles.contentSection} ${
          menuOpen ? styles.sideBarOpen : styles.sideBarClose
        } mt-5`}
      >
        <Row className="mb-5">
          <Col md={5}>
            <Form.Control
              type="text"
              placeholder="Filtrar por Nome ou CPF"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </Col>
          <Col md={4}>
            <Select
              options={bankOptions}
              placeholder="Filtrar por Banco"
              isClearable
              onChange={(selected) => setBankFilter(selected ? selected.value : null)}
              menuPortalTarget={document.body}
              menuPosition="fixed"
              menuPlacement="auto"
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              }}
            />
          </Col>
          <Col md={3}>
            <Form.Control
              type="date"
              value={dateRange.start}
              onChange={(e) => setDateRange((prev) => ({ ...prev, start: e.target.value }))}
            />
          </Col>
        </Row>

        <Table className={`${styles.tableStyle} table table-responsive`}>
          <thead>
            <tr>
              <th>DATA</th>
              <th>CPF</th>
              <th>CLIENTE</th>
              <th>BANCO</th>
              <th>OPERACÃO</th>
              <th>VALOR</th>
              <th>DADOS</th>
            </tr>
          </thead>
          <tbody>
            {currentPageClients.map((client, index) => (
              <tr key={index}>
                <td>{new Date(client.date).toLocaleDateString()}</td>
                <td>{client.client.CPF}</td>
                <td>{client.client.name}</td>
                <td>{client.creditCondition.selectedBank.bankFullName}</td>
                <td>{client.creditCondition.formalizationType}</td>
                <td>
                  {new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(Number(client.creditCondition.installmentAmount) || 0)}
                </td>
                <td>
                  <ButtonGrayUltimate
                    onClick={() => {
                      setSelectedClient(client);
                      setShowModal(true);
                    }}
                    content={<FontAwesomeIcon icon={faPenToSquare}/>}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Pagination>
          {Array.from({ length: totalPages }, (_, index) => (
            <Pagination.Item
              key={index}
              active={index + 1 === currentPage}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
        </Pagination>
      </Container>

      {selectedClient && (
        <ClientModal
          show={showModal}
          clients={[selectedClient]}
          onClose={() => setShowModal(false)}
          onSave={() => {
            console.log("Cliente salvo:", selectedClient);
          }}
        />
      )}
    </>
  );
}
