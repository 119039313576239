import React, { useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import axios from 'axios';
import styles from './SendFile.module.scss';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faDownload } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx';
import { URL_SIMULATIONS } from '../../../config';
import NavBar from '../../../components/NavBar';
import Logo from '../../../components/Logo';
import PageTitle from '../../../components/PageTitle';
import PageSubTitle from '../../../components/PageSubTitle';
import ButtonPrimary from '../../../components/Buttons/ButtonPrimary';
import ToastValidationSimulation from '../../../components/ToastValidationSimulation';
import ModalErrorBatchFile from '../../../components/Modals/Errors/ModalErrorBatchFile';

export default function SendFileInssBatchTyping() {
  const [menuOpen, setMenuOpen] = useState<boolean>(localStorage.getItem('isMenuOpen') === 'true');
  const [toastMessage, setToastMessage] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [uniqueCpfCount, setUniqueCpfCount] = useState<number | null>(null);

  const subtitleTextClient = "Anexe o arquivo conforme o modelo padrão.";

  const handleBack = (e: React.FormEvent) => {
    window.location.href = '/DigitacoesLote/INSS/SelecaoBancos';
  };

  const handleDownloadExample = async () => {
    try {
      // Recupera os bancos selecionados do localStorage
      const selectedBanks = JSON.parse(localStorage.getItem("selectedBankNamesTyping") || "[]");
  
      // Verifica se o valor é um array válido e extrai o primeiro item como string limpa
      if (!Array.isArray(selectedBanks) || selectedBanks.length === 0) {
        setToastMessage("Nenhum banco selecionado.");
        return;
      }
  
      // Garante que o nome do banco será uma string em letras minúsculas
      const bankName = selectedBanks[0]?.toString().toLowerCase();
  
      // Verifica se o nome do banco está presente
      if (!bankName) {
        setToastMessage("Nome do banco inválido.");
        return;
      }
  
      const response = await axios.post(
        `${URL_SIMULATIONS}/proposal/batch-digitation/build-document`,
        null, // Corpo vazio porque o parâmetro será enviado pela URL
        {
          params: { bankName }, // Passa o parâmetro diretamente na URL
          responseType: "blob", // Configura para receber o arquivo como blob
        }
      );
  
      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Template-Digitacao-Em-Lote.xlsx";
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        setToastMessage("Erro ao baixar o modelo.");
        console.log(response.status);
      }
    } catch (error) {
      console.error("Erro no download:", error);
      setToastMessage("Erro inesperado ao tentar realizar o download.");
    }
  };
  
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      if (file && file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        setSelectedFile(file);
        processFile(file);
        setShowErrorModal(false);
      } else {
        setShowErrorModal(true);
        setSelectedFile(null);
      }
    }
  };

  const processFile = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target?.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheet = workbook.Sheets[workbook.SheetNames[0]]; // Assume que os CPFs estão na primeira planilha

      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 }) as unknown[]; // Faz o cast para unknown[]
      const cpfColumnIndex = 0; // Índice da coluna que contém os CPFs

      const cpfs = (jsonData as (string[] | any[])[]) // Faz o cast para uma matriz de arrays
        .slice(1) // Ignora o cabeçalho
        .map((row: any[]) => row[cpfColumnIndex])
        .filter((cpf: string | undefined) => cpf); // Filtra valores não definidos

      const uniqueCpfs = new Set(cpfs);
      setUniqueCpfCount(uniqueCpfs.size);
    };

    reader.readAsArrayBuffer(file);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  
    // Verifica se o arquivo foi selecionado
    if (!selectedFile || uniqueCpfCount === null) {
      setToastMessage('Selecione algum arquivo para prosseguirmos.');
      return;
    }
  
    const formData = new FormData();
  
    // Adiciona o arquivo à chave 'file' do FormData
    formData.append('file', selectedFile);
  
    const token = localStorage.getItem('token');
    
    try {
      // Requisição POST com Bearer Token
      const response = await axios.post(
        'https://acelereai.gfttech.com.br/api/v1/proposal/batch-digitation/upload-document',
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data', // Pode ser removido, pois o axios faz isso automaticamente
          },
        }
      );
  
      if (response.status === 200) {
        const message = response.data.message; // Mensagem de sucesso retornada pela API
        alert(message); // Exibe um alerta com a mensagem
  
        // Atualiza a página após sucesso
        window.location.reload();
      } else {
        setToastMessage(response.data.message || 'Erro ao enviar a planilha.');
      }
    } catch (error) {
      setToastMessage('Erro ao realizar a requisição.');
    }
  };
  
  return (
    <>
      <section className={styles.navbarSection}>
        <NavBar onToggleMenu={(isMenuOpen: boolean) => setMenuOpen(isMenuOpen)} />
      </section>
      <Logo/>

      <motion.div
        initial={{ x: 1000, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 1000, opacity: 0 }}
        transition={{ duration: 1.2 }}
      >
        <div className={styles.titlesSection}>
          <div className={`${styles.titleSection} mt-5`}>
            <PageTitle size="h2">Digitação em Lote</PageTitle>
          </div>
          <div className={`${styles.subTitleSection} mb-3`}>
            <PageSubTitle size='h5'>{subtitleTextClient}</PageSubTitle>
          </div>
        </div>

        <Container className={`${styles.contentSection} ${menuOpen ? styles.sideBarOpen : styles.sideBarClose}`}>
          <Row className='mt-5'>
            <Col md={4} sm={12}>
              <ButtonPrimary content={<><FontAwesomeIcon icon={faDownload} /> Baixar modelo padrão</>} onClick={handleDownloadExample}/>
            </Col>
          </Row>

          <Row className='mt-4 mb-5'>
            <Col md={4} sm={12}>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Control type="file" onChange={handleFileChange}/>
              </Form.Group>
            </Col>
          </Row>

          <Row className='mt-5'>
            <Col className='d-flex justify-content-start mt-5'>
              <ButtonPrimary 
                content={<><FontAwesomeIcon icon={faArrowLeft} /> <span>Voltar</span></>} 
                onClick={handleBack}
              />
            </Col>
            <Col className='d-flex justify-content-end mt-5'>
              <ButtonPrimary 
                content={<><span>Prosseguir</span> <FontAwesomeIcon icon={faArrowRight} /></>} 
                onClick={handleSubmit}
              />
            </Col>
          </Row>
          
        </Container>
        {toastMessage && <ToastValidationSimulation message={toastMessage} />}
        {showErrorModal && <ModalErrorBatchFile show={showErrorModal} onClose={() => setShowErrorModal(false)} />}
      </motion.div>
    </>
  );
}
